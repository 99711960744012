import {
	SET_PARAMS,
	SET_LOCAL,
	PARAMS_LOCALS_RK,
	PARAMS_LOCAL_RK,
	PARAMS_DEFAULT_LOCAL_RK,
	SET_DEFAULT_LOCAL,
} from './constants';

const defaultValues = {
	[PARAMS_LOCALS_RK]: [],
	[PARAMS_LOCAL_RK]: null,
	[PARAMS_DEFAULT_LOCAL_RK]: null,
};

const paramsReducer = (state = defaultValues, { payload, type }) => {
	const newState = {...state};
	switch (type) {
		case SET_PARAMS:
			return { ...payload };
		case SET_LOCAL:
			newState[PARAMS_LOCAL_RK] = payload;
			return newState;
		case SET_DEFAULT_LOCAL:
			newState[PARAMS_DEFAULT_LOCAL_RK] = payload;
			return newState;
		default:
			return newState;
	}
};

export default paramsReducer;
