const APICONFIG = {
  clientId: '1_',
  clientSecret: '1',
  url: 'https://www.assurances-etudiants.com',
  nodeServer: 'https://www.assurances-etudiants.com',
  assetUrl: 'https://www.assurances-etudiants.whatson-web.com',
  endPointMedia: 'https://www.assurances-etudiants.com/api/files',
  helpAdmin: 'https://www.assurances-etudiants.com/docs/static',
  help: 'https://www.assurances-etudiants.com/docs/static',
  finderParams : {
    url : 'https://www.assurances-etudiants.com',
    endPointMedia : '/api/media',
    assetUrl : 'https://www.assurances-etudiants.com',
  }
};

module.exports = APICONFIG;
