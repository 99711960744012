import * as store from './storage';
import * as api from './api';

// const jwt_token = null;

export const user = {
	id: null,
	name: null,
	email: null,
	roles: null,
};

export const login = (username, password) =>
	api
		.login(username, password)
		.then(response => {
			store.save('jwt_token', response['token']);
		})
		.catch(exception => {
			throw exception;
		});

export const logout = () => {
	store.destroy('jwt_token');
	return Promise.resolve('disconnected');
};

export const checkRole = ROLE => {
	let res = false;

	user.roles.map(el => {
		if (el == ROLE) res = true;
	});

	return res;
};
/**
 *
 * @returns {*}
 */
export const getJwtToken = () => store.get('jwt_token');

export const isAuthenticated = () =>
	// const jwtToken = getJwtToken();

	api
		.ping()
		.then(response => {
			user.id = response.id;
			user.name = response.name;
			user.email = response.email;
			user.roles = response.roles;

			return Promise.resolve(response);
		})
		.catch(() => Promise.reject('disconnected'));

export const getUser = () => {
	//Si pas de token stoké dans la class, on tente de récupérer ceux en local storage
	if (!user.id) {
		const storedUser = store.get('user');

		if (storedUser && storedUser.id) {
			user.id = storedUser.id;
			user.name = storedUser.name;
			user.email = storedUser.email;
			user.picture = storedUser.picture;
			user.roles = storedUser.roles;
			user.costumer = storedUser.costumer;
			user.costumers = storedUser.costumers;
			user.affiliated = storedUser.affiliated;
		}
	}

	return user;
};

export const testRole = roleToTest => {
	if (!roleToTest) return true;

	const user = getUser();

	if (!user) return false;
	if (!user.roles) return false;

	const test = user.roles.filter(role => role == roleToTest);

	return test.length ? true : false;
};
