import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { FieldTxt, FieldSelect, FieldDate } from '@whatsonweb/fields';
import { href } from 'react-friends';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSubscriptions, setFilters, setActiveFilters } from '../redux/';
import convertFilters from '../../../utils/convertFiltersNew';
import { convertItemAsValuesCollectionByMappingProperties } from './utils';
import { statusTypes, labelSize, propertyToLabelMapper } from './constants';
import './filters.scss';

class CustomerFilter extends Component {
	submit = evt => {
		evt.preventDefault();
		const labels = this.props.currentValues
			? convertItemAsValuesCollectionByMappingProperties(
					this.props.currentValues,
					propertyToLabelMapper
			  )
			: [];
		this.props.setActiveFilters(labels);
		//this.props.onSubmit(prepareFiltersToBeParsedAsQueryParams(this.props.currentValues || {}));
		this.props.setFilters(
			this.props.currentValues ? convertFilters(this.props.currentValues) : []
		);
		this.props.onClose();
	};

	render() {
		const { reset, change } = this.props;
		const { submit } = this;
		return (
			<form className="form-horizontal filters">
				<div className="flags-header">
					{statusTypes.map(({ value, name, className }) => (
						<a {...href} key={value}>
							<i
								className={`fa fa-flag fa-2x icon-${className}`}
								onClick={() => change('contracts[exists]', value == 0 ? '' : value)}
								title={name}
							/>
						</a>
					))}
				</div>

				<Field
					label="Etat/statut"
					name="contracts[exists]"
					options={statusTypes}
					component={FieldSelect}
					{...labelSize}
				/>
				<button type="button" className="btn btn-outline-success" onClick={submit}>
					<i className="fa fa-check" />
					Valider
				</button>
				<button type="button" className="btn btn-outline-danger" onClick={reset}>
					<i className="fa fa-times" />
					Reset
				</button>
			</form>
		);
	}
}

CustomerFilter.defaultProps = {
	customers: [],
};

CustomerFilter.propTypes = {
	reset: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	change: PropTypes.func.isRequired,
};

const formName = 'customersFilter';
export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	customers: getSubscriptions(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators({ setActiveFilters, setFilters }, dispatch);

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps
);

const withReduxForm = reduxForm({ form: formName });

export default compose(
	withConnect,
	withReduxForm
)(CustomerFilter);
