/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { FieldTxt, FieldSelect, FieldDate } from '@whatsonweb/fields';
import { href } from 'react-friends';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSubscriptions, setFilters, setActiveFilters } from '../redux/';
import { getArticles } from '../../Catalog/Article/redux/selectors';
import convertFilters from '../../../utils/convertFiltersNew';
import {
	getYearsFromSubscriptions,
	convertItemAsValuesCollectionByMappingProperties,
	formatData,
} from './utils';
import {
	affiliatedTypes,
	paymentTypes,
	statusTypes,
	booleanSelect,
	labelSize,
	propertyToLabelMapper,
} from './constants';
import './filters.scss';
import './../icon.scss';
import {getProducts} from "../../Catalog/Product/redux";
import * as affiliatedService from '../../../services/affiliated';
import * as seoService from "../../../services/seo";

class SubscriptionFilter extends Component {

	state = {
		currentFormule : null,
		affiliated : []
	};

	componentDidMount = () => {
		return affiliatedService.search().then((data) => {

			const lst = [
				{name : 'Tous',value : ''},
				{name : 'ADH',value : 'adh'},
			];

			this.setState({affiliated: []}, () => {
					data['hydra:member'].map((el,index) => {
						lst.push({name : el.name, value:  el.id});
					});
					this.setState({affiliated: lst});
				})
			});
	};

	submit = evt => {
		evt.preventDefault();

		const labels = this.props.currentValues
			? convertItemAsValuesCollectionByMappingProperties(
					this.props.currentValues,
					propertyToLabelMapper
			  )
			: [];
		this.props.setActiveFilters(labels);

		//this.props.onSubmit(prepareFiltersToBeParsedAsQueryParams(this.props.currentValues || {}));
		this.props.setFilters(
			this.props.currentValues ? convertFilters(this.props.currentValues) : []
		);

		this.props.onClose();
	};

	getFormule = () => {
		const { articles } = this.props;

		const formules = [{value:null,name : 'Tous'}];
		const formuleIds = [];

			articles.map(article => {
				if (!formuleIds.includes(article.product.id)) {
					formuleIds.push(article.product.id);
					formules.push({value : article.product.id,name :article.product.name});
				}
			});



		return formules;
	};

	getNbrRooms = () => {
		const { articles } = this.props;
		const { currentFormule } = this.state;

		const rooms = [{value : null,name : 'Tous'}];
		const roomsIds = [];

		if(currentFormule) {
			articles.map(article => {
				if (currentFormule == article.product.id &&  !roomsIds.includes(article.name)) {
					roomsIds.push(article.name);
					rooms.push({value : article.id, name : article.name});
				}
			});
		}

		return rooms;
	};

	render() {
		const { reset, change, subscriptions } = this.props;
		const {affiliated} = this.state;
		const { submit } = this;
		return (
			<form className="form-horizontal filters">
				<div className="row form-group">
					<div className="col-md-12">
					<button type="button" className="btn btn-outline-success" onClick={submit}>
						<i className="fa fa-check" />
						Valider
					</button>
					<button type="button" className="btn btn-outline-danger" onClick={reset}>
						<i className="fa fa-times" />
						Reset
					</button>
					</div>
				</div>
				<div className="flags-header">
					{statusTypes.map(({ value, name, className }) => (
						<a {...href} key={value}>
							<i
								className={`fa fa-flag fa-2x icon-${className}`}
								onClick={() => change('status', value == 0 ? '' : Number(value))}
								title={name}
							/>
						</a>
					))}
				</div>
				<Field
					label="Date 1ère souscription >="
					name="startDate[after]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Date 1ère souscription <="
					name="startDate[before]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Année"
					name="startDate"
					component={FieldSelect}
					{...labelSize}
					options={getYearsFromSubscriptions(subscriptions || [])}
				/>
				<Field
					label="Date révision/création >="
					name="created[after]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Date révision/création <="
					name="created[before]"
					component={FieldDate}
					{...labelSize}
				/>
				<Field
					label="Num. adhésion :"
					name="membershipNumber"
					component={FieldTxt}
					{...labelSize}
				/>
				<Field name="id" component={FieldTxt} label="ID db :" {...labelSize} />
				<Field name="transactions-uuid" component={FieldTxt} label="Transaction Uuid :" {...labelSize} />
				<Field name="insured-id" component={FieldTxt} label="ID client :" {...labelSize} />
				<Field name="insured-email" component={FieldTxt} label="Email :" {...labelSize} />
				<Field name="insured-phone" component={FieldTxt} label="Tél. :" {...labelSize} />
				<Field name="insured-lastname" component={FieldTxt} label="Nom :" {...labelSize} />
				<Field
					name="insured-firstname"
					component={FieldTxt}
					label="Prénom :"
					isRequired={false}
					{...labelSize}
				/>
				<Field
					name="housing-zipCode"
					component={FieldTxt}
					label="Code Postal :"
					isRequired={false}
					{...labelSize}
				/>
				<Field
					name="housing-city"
					component={FieldTxt}
					isRequired={false}
					label="Ville :"
					{...labelSize}
				/>
				<Field
					label="Etat/statut"
					name="status"
					options={statusTypes}
					component={FieldSelect}
					{...labelSize}
				/>
				<Field
					label="Formule"
					name="label-product-id"
					required={false}
					nullValue="choisir"
					onChange={(event,value) => {
						this.setState({currentFormule : value})
					}}
					isRequired={false}
					component={FieldSelect}
					options={this.getFormule()}
					{...labelSize}
				/>
				<Field
					label="Nbr pièces"
					name="label-article-id"
					required={false}
					nullValue="choisir"
					isRequired={false}
					component={FieldSelect}
					options={this.getNbrRooms()}
					{...labelSize}
				/>
				<Field
					label="Avec coloc"
					name="roomMate.exists"
					required={false}
					nullValue="choisir"
					isRequired={false}
					component={FieldSelect}
					options={booleanSelect}
					{...labelSize}
				/>
				<Field
					label="Nbr colocs"
					name="roomMateNumber"
					isRequired={false}
					component={FieldTxt}
					{...labelSize}
				/>
				<Field
					label="Extention bureautique"
					name="insured-officeGuarantee"
					required={false}
					nullValue="choisir"
					isRequired={false}
					component={FieldSelect}
					options={booleanSelect}
					{...labelSize}
				/>
				<Field
					label="Utilisateur affilié"
					name="user-affiliated"
					component={FieldSelect}
					options={affiliated}
					{...labelSize}
				/>
				<button type="button" className="btn btn-outline-success" onClick={submit}>
					<i className="fa fa-check" />
					Valider
				</button>
				<button type="button" className="btn btn-outline-danger" onClick={reset}>
					<i className="fa fa-times" />
					Reset
				</button>
			</form>
		);
	}
}

SubscriptionFilter.defaultProps = {
	subscriptions: [],
	products: [],
	articles: [],
};

SubscriptionFilter.propTypes = {
	reset: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	change: PropTypes.func.isRequired,
	subscriptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	products: PropTypes.arrayOf(PropTypes.shape({})),
	articles: PropTypes.arrayOf(PropTypes.shape({})),
};

const formName = 'subscriptionFilter';
export const mapStateToProps = state => ({
	currentValues: getFormValues(formName)(state),
	subscriptions: getSubscriptions(state),
	products: getProducts(state),
	articles: getArticles(state),
});

export const mapDispatchToProps = dispatch =>
	bindActionCreators({ setActiveFilters, setFilters }, dispatch);

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps
);
const withReduxForm = reduxForm({ form: formName });

export default compose(
	withConnect,
	withReduxForm
)(SubscriptionFilter);
