import { createAction } from 'redux-friends';

import MyTypes from './types';
import { SET_PRODUCTS } from './constants';
import * as producService from '../../../../services/catalog/product';

export const setProducts = createAction(MyTypes[SET_PRODUCTS]);

export const fetchProducts = (state = {}) => async dispatch => {
	try {
		const products = (await producService.search(state))['hydra:member'];
		dispatch(setProducts(products));
	} catch (error) {
		console.error({ error });
	}
};
