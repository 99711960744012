/* eslint-disable import/no-unresolved */
import { setParams, setLocal } from './actions';
import { getParams, getLocals, getLocal, getDefaultLocal } from './selectors';
import { PARAMS_RK } from './constants';
import paramsReducer from './reducer';

export {
	setParams,
	setLocal,
	getParams,
	getLocals,
	getLocal,
	getDefaultLocal,
	PARAMS_RK,
	paramsReducer,
};
