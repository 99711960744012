import { fetch, createMethods } from '../../utils/apiStuff';
import configApi from '../../constants/ApiConfig';

const endPoints = '/api/contracts';

export const { search, view, create,  remove, update } = createMethods(endPoints);

export const estimate = data => fetch(`${configApi.url}${endPoints}/estimate`, 'post', data);
export const cancel = id => fetch(`${configApi.url}${endPoints}/cancel/${id}`, 'post');
export const archive = id => fetch(`${configApi.url}${endPoints}/archive/${id}`, 'post');
export const exporte = () => fetch(`${configApi.url}${endPoints}/export`, 'get');
export const changeStatus = (id,status) => fetch(`${configApi.url}${endPoints}/change-status/${id}/${status}`, 'get');
export const regenerateContract = (id) => fetch(`${configApi.url}${endPoints}/send-contract/${id}`, 'get');
export const historical = (id) => fetch(`${configApi.url}${endPoints}/${id}/historical`, 'get');
