import params from './params.json';

const CONFIG = {
    author: {
        name: 'Whatson web',
        url: 'https://www.whatson-web.com',
    },
    costumer: {
        name: 'ADH - Chasse',
        url: 'https://www.adh-chasse.fr',
    },
    ROLES: [
        { value: 'ROLE_USER', label: 'Utilisateur' },
        { value: 'ROLE_ADMIN_AFFILIATED', label: 'Admin Affilié' },
        { value: 'ROLE_DIS_MAN', label: 'Gestionnaire de sinistre' },
        { value: 'ROLE_ADMIN', label: 'Admin' },
    ],
    PROGRAM_PUBLICATION_STATUSES: [
        {
            value: 0,
            name: 'Brouillon',
            label: 'Brouillon',
            color: 'dark',
        },
        {
            value: 50,
            name: 'Désactivé',
            label: 'Désactivé',
            color: 'danger',
        },
        {
            value: 100,
            name: 'Prêt à être publié',
            label: 'Prêt à être publié',
            color: 'warning',
        },
        {
            value: 200,
            name: 'Publié',
            label: 'Publié',
            color: 'success',
        },
    ],

    CMS_PARAMETERS: [
        { value: 'string', name: 'Valeur courte' },
        { value: 'text', name: 'Valeur longue' },
        { value: 'external-link', name: 'Lien externe' },
        { value: 'internal-link', name: 'Lien interne' },
        { value: 'image', name: 'Image' },
        { value: 'file', name: 'File' },
    ],
    SEO_ENTITIES: [{ value: 'Page', name: 'Page' }, { value: 'Post', name: 'Post' }],
    ORGANISATION_CIVILITIES: params.ORGANISATION_CIVILITIES,
};

export default CONFIG;
