const convertFilters = data => {
	const filters = [];

	const mapObj = { '-': '.', '{': '[', '}': ']' };

	Object.keys(data).map((objectKey, index) => {
		const str = objectKey.replace(/\{|\}|-/gi, matched => mapObj[matched]);

		if(str === 'user.affiliated' && data[objectKey] === 'adh') {
			filters.push({ id: str+'[exists]', value: false });
		} else {
			filters.push({ id: str, value: data[objectKey] });
		}

		console.log(filters);

	});


	return filters;
};

export default convertFilters;
