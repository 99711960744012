/* eslint-disable import/no-unresolved */
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import Loading from '@whatsonweb/loading';
import { bubble as Menu } from 'react-burger-menu';

import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar/';
import Breadcrumb from '../../components/Breadcrumb';

import Footer from '../../components/Footer';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import * as session from '../../services/session';
import LeftSidebarContent from '../../components/LeftSidebarContent';
import { NullLoadable } from 'react-friends';

import { setParams } from '../../redux/reducers/params';

import {
	getSidebarOpen,
	setSidebarOpen,
	getSidebarWidth,
} from '../../components/LeftSidebarContent/redux';

import './full.scss';

const LazyRoutesContainer = NullLoadable(() => import('./RoutesContainer'));

class Full extends Component {
	state = { loaded: false };

	componentDidMount() {
		this.checkAuthentication();
	}

	checkAuthentication = async () => {
		const { setParams, onSetUser, history } = this.props;

		try {
			const data = await session.isAuthenticated();
			onSetUser(data);
			await setParams();

			this.setState({ loaded: true });
		} catch (error) {
			history.push('/login');
		}
	};

	render() {
		if (!this.state.loaded) return <Loading />;
		const { isSidebarOpen, onSetSidebarOpen, menuWidth } = this.props;

		return (
			<div className="app">
				<Menu
					isOpen={isSidebarOpen}
					onStateChange={({ isOpen }) => onSetSidebarOpen(isOpen)}
					width={menuWidth}
					right>
					<LeftSidebarContent />
				</Menu>
				<Header />

				<ToastContainer position="top-center" hideProgressBar autoClose={3000} />

				<div className="app-body">
					<Sidebar {...this.props} />

					<main className="main">
						<Breadcrumb />
						<LazyRoutesContainer />
					</main>
				</div>

				<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	user: state.user,
	isAuthenticated: state.isAuthenticated,
	isSidebarOpen: getSidebarOpen(state),
	menuWidth: getSidebarWidth(state),
});

const setUser = user => ({
	user,
	type: 'SET_USER',
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{ onSetUser: setUser, onSetSidebarOpen: setSidebarOpen, setParams },
		dispatch
	);

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Full);
