/* eslint-disable import/no-unresolved */
import { SET_PARAMS, SET_LOCAL, SET_DEFAULT_LOCAL, PARAMS_RK, PARAMS_LOCAL_RK } from './constants';
import { getParams } from '../../../services/configuration';

export const setParams = () => async (dispatch, getState) => {
	const params = await getParams();

	dispatch({
		type: SET_PARAMS,
		payload: params,
	});

	const state = getState();
	if (!state[PARAMS_RK][PARAMS_LOCAL_RK]) {
		dispatch({
			type: SET_LOCAL,
			payload: params.locals[0],
		});
	}

	dispatch({
		type: SET_DEFAULT_LOCAL,
		payload: params.locals[0],
	});
};

export const setLocal = local => {
	return {
		type: SET_LOCAL,
		payload: local,
	};
};

export default null;
