import React, { Component } from 'react';
import ApiConfig from '../../constants/ApiConfig';
import { Nav, NavItem, NavbarToggler, NavbarBrand, NavLink } from 'reactstrap';

import HeaderDropdownAccount from './HeaderDropdownAccount';
import HeaderDropdownCms from './HeaderDropdownCms';

const logo = `url(img/logo-small.svg)`;

class Header extends Component {
	refresh() {
		window.location.reload();
	}

	sidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle('sidebar-hidden');
	}

	sidebarMinimize(e) {
		e.preventDefault();
		document.body.classList.toggle('sidebar-minimized');
	}

	mobileSidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle('sidebar-mobile-show');
	}

	asideToggle(e) {
		e.preventDefault();
		document.body.classList.toggle('aside-menu-hidden');
	}

	render() {
		return (
			<header className="app-header navbar">
				<NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
					<span className="navbar-toggler-icon" />
				</NavbarToggler>
				<NavbarBrand
					href="#"
					style={{ backgroundImage: logo, backgroundColor: '#52a702' }}
				/>
				<NavbarToggler
					className="d-md-down-none sidebar-minimizer h-100 b-r-1"
					style={{ backgroundColor: 'rgb(221, 255, 189)' }}
					onClick={this.sidebarToggle}>
					<span className="navbar-toggler-icon" />
				</NavbarToggler>
				<Nav className="" navbar>
					<NavItem className="d-md-down-none">
						<NavLink href="#/" style={{ padding: '0 20px' }}>
							<i className="icon-speedometer" /> Mon Dashboard
						</NavLink>
					</NavItem>
				</Nav>

				<Nav className="ml-auto" navbar>
					<HeaderDropdownAccount />
					<NavItem className="d-md-down-none">
						<NavLink href="javascript:void(0);" onClick={() => this.refresh()}>
							<i className="fa fa-refresh" />
						</NavLink>
					</NavItem>
					<NavItem className="d-md-down-none">
						<NavLink href={`${ApiConfig.helpAdmin}/index.html`} target={'_blank'}>
							<i className="fa fa-question-circle" />
						</NavLink>
					</NavItem>
				</Nav>

				{/*
                <NavbarToggler className="d-md-down-none" onClick={this.asideToggle}>
                    <span className="icon-settings"></span>
                </NavbarToggler>*/}
			</header>
		);
	}
}

export default Header;
