/* eslint-disable import/no-unresolved */
import { fetch } from '../../services/api';
import QueryBuilder from '../../components/WHTable/queryBuilder';
import configApi from '../../constants/ApiConfig';

const endPoints = '/api/configurations';

export const search = data => {
	let query = QueryBuilder(data);

	return fetch(`${configApi.url}${endPoints}`, 'get', query);
};

export const getParams = () => fetch(`${configApi.url}/fr/api/parameters`, 'get');

export const view = id => fetch(`${configApi.url}${endPoints}/${id}`, 'get');

export const create = data => fetch(`${configApi.url}${endPoints}`, 'post', data);

export const update = data => fetch(`${configApi.url}${endPoints}/${data.id}`, 'put', data);

export const remove = id => fetch(`${configApi.url}${endPoints}/${id}`, 'delete');

export const active = id => fetch(`${configApi.url}${endPoints}/active/${id}`, 'get');
