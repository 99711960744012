import { fetch } from '../../services/api';
import QueryBuilder from '../../components/WHTable/queryBuilder';

const endPoints = '/api/seos';

export const search = (data) => {

    let query = QueryBuilder(data);

    return fetch(endPoints, 'get', query)

};

export const view = (id) => fetch(`${endPoints}/${id}`, 'get');

export const create = (data) => fetch(`${endPoints}`, 'post', data);

export const update = (data) => fetch(`${endPoints}/${data.id}`, 'put', data);

export const remove = (id) => fetch(`${endPoints}/${id}`, 'delete', {});